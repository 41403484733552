import {Select, Tabs} from "antd";
import Crud from "../../lib/Crud";
import React, {useContext, useEffect, useState} from "react";
import api from "../../lib/util";
import PageHeader from "../../components/mock/PageHeader";
import {PermissionsContext} from "../../lib/PermissionsContext";

function Access() {

    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const {hasPermission} = useContext(PermissionsContext);

    const canEdit = hasPermission('users.edit');

    const loadRoles = () => {
        api.getJSON('/api/role').then(response => {
            const roles = response.map((item, index) => {
                return {
                    key: index,
                    value: item.id,
                    label: item.name
                }
            });
            setRoles(roles);
        })
    }

    const loadPermissions = () => {
        api.getJSON('/api/permission').then(response => {
            const permissions = response.map((item, index) => {
                return {
                    key: index,
                    value: item.id,
                    label: item.name
                }
            });
            setPermissions(permissions);
        })
    }

    useEffect(() => {
        loadRoles();
        loadPermissions();
    }, []);

    const getTabItems = () => {

        const tabItems = [
            {
                key: 1, label: 'Сотрудники', children: <Crud
                    actions={['create', 'edit']}
                    title={'Сотрудники'}
                    initialItemValues={{is_active: true}}
                    viewConfig={[
                        {title: 'ФИО', dataIndex: 'name', key: 'name'},
                        {title: 'Email', dataIndex: 'email', key: 'email'},
                        {title: 'Роли', dataIndex: 'roles', key: 'roles'},
                        {
                            title: 'Активен', dataIndex: 'is_active', key: 'is_active', render: (record) => {
                                return record ? 'Да' : 'Нет';
                            }
                        },
                        {title: 'Телефон', dataIndex: 'phone', key: 'phone'},
                        {title: 'Должность', dataIndex: 'position', key: 'position'},
                    ]}
                    editConfig={[
                        {
                            title: 'ФИО',
                            type: 'text',
                            name: 'name',
                            autoFocus: true,
                            rules: [{required: true, message: 'Поле обязательно для заполнения'}]
                        },
                        {
                            title: 'Email', type: 'text', name: 'email', rules: [
                                {required: true, message: 'Поле обязательно для заполнения'},
                                {type: 'email', message: 'Неверный формат email'}
                            ]
                        },
                        {
                            title: 'Пароль', type: 'text', name: 'password',
                            rules: (item) => {
                                if (!item.id) {
                                    return [{required: true, message: 'Поле обязательно для заполнения'}];
                                }
                            }
                        },
                        {title: 'Контактный телефон', type: 'text', name: 'phone'},
                        {title: 'Активен', type: 'checkbox', name: 'is_active'},
                        {
                            title: 'Должность',
                            type: 'text',
                            name: 'position',
                            rules: [{required: true, message: 'Поле обязательно для заполнения'}]
                        },
                        {
                            title: 'Роли', name: 'roles', required: true, render: () => {
                                return <Select mode={'multiple'} options={roles}/>;
                            }, rules: [{required: true, message: 'Поле обязательно для заполнения'}]
                        },
                    ]}
                    editTitle={'Редактирование пользователя'}
                    createTitle={'Создание пользователя'}
                    validatorConfig={[]}
                    url="/api/user"
                    onMapItem={(item) => {
                        item.roles = item.roles?.map(role => role.id);
                        return item;
                    }}
                    onMapLoad={(map) => {
                        return map.map(item => {
                            item.roles = item.roles?.map(role => role.name).join(', ');
                            return item;
                        });
                    }}
                />
            },
        ];

        if (canEdit) {
            tabItems.push({
                key: 2, label: 'Роли', children: <Crud
                    title={'Роли'}
                    canEdit={canEdit}
                    viewConfig={[
                        {title: 'Name', dataIndex: 'name', key: 'name'},
                        {title: 'Description', dataIndex: 'description', key: 'description'},
                    ]}
                    editConfig={[
                        {
                            title: 'Название', type: 'text', name: 'name', autoFocus: true,
                            rules: [{required: true, message: 'Поле обязательно для заполнения'}]
                        },
                        // пока убрал описание потому что оно не сохраняется на сервере
                        // {
                        //     title: 'Описание',
                        //     type: 'text',
                        //     name: 'description',
                        //     rules: [{required: true, message: 'Поле обязательно для заполнения'}]
                        // },
                        {
                            title: 'Разрешения', name: 'permissions', required: true, render: (item) => {
                                return <Select mode={'multiple'} options={permissions}></Select>;
                            }
                        },
                    ]}
                    editTitle={'Редактирование роли'}
                    createTitle={'Создание роли'}
                    validatorConfig={[]}
                    url="/api/role"
                    onChange={() => {
                        loadRoles();
                    }}
                    onMapItem={(item) => {
                        item.permissions = item.permissions?.map(permission => permission.id);
                        return item;
                    }}
                    onMapLoad={(map) => {
                        return map.map(item => {
                            item.permissions = item.permissions?.map(permission => permission.name).join(', ');
                            return item;
                        });
                    }}
                />
            });

            if (canEdit) {
                tabItems.push({
                    key: 3, label: 'Разрешения',
                    children: <Crud
                        title={'Разрешения'}
                        canEdit={canEdit}
                        viewConfig={[
                            {title: 'Название', dataIndex: 'name', key: 'name'},
                            {title: 'Описание', dataIndex: 'description', key: 'description'},
                            {title: 'Код', dataIndex: 'code', key: 'code'},
                        ]}
                        editConfig={[
                            {
                                title: 'Название',
                                type: 'text',
                                name: 'name',
                                autoFocus: true,
                                rules: [{required: true, message: 'Поле обязательно для заполнения'}]
                            },
                            {
                                title: 'Код',
                                type: 'text',
                                name: 'code',
                                rules: [{required: true, message: 'Поле обязательно для заполнения'}]
                            },
                            {
                                title: 'Описание',
                                type: 'text',
                                name: 'description',
                                rules: [{required: true, message: 'Поле обязательно для заполнения'}]
                            },
                        ]}
                        editTitle={'Редактирование разрешения'}
                        createTitle={'Создание разрешения'}
                        validatorConfig={[]}
                        url="/api/permission"
                        onChange={() => {
                            loadPermissions();
                        }}
                    />
                });
            }
        }
        return tabItems;
    }

    return <>
        <PageHeader title="Пользователи" path={'/access'}/>
        <div style={{background: '#fff', padding: 24, minHeight: 280}}>
            <Tabs items={getTabItems()} defaultActiveKey={1}/>
        </div>
    </>

}

export default Access;
