import {useEffect, useState} from "react";
import {
    Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Table, ConfigProvider, Spin
} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import api from "./util";
import PropTypes from "prop-types";
import {useForm} from "antd/lib/form/Form";
import {NotifyError, NotifySuccess} from "./notify";
import locale from 'antd/lib/locale/ru_RU';
import List from "./List";
import dayjs from "dayjs";

function Crud(props) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState({name: 'initial'});
    const [editMode, setEditMode] = useState(false);
    const [formInstance] = useForm();
    const [selectedRowKeys, setSelectedRowKeys] = useState(props.selectedRowKeys || []);
    const [update, setUpdate] = useState(Math.random());
    const [searchString, setSearchString] = useState();

    const getViewConfig = () => {

        if (!hasCreateAction() && !hasEditAction() && !hasDeleteAction()) {
            return props.viewConfig;
        }

        const config = [...props.viewConfig, {
            title: 'Операции',
            dataIndex: '',
            width: '100px',
            key: 'edit',
            align: 'center',
            render: (record) => {
                return <div>
                    {hasEditAction(record) && <Button className={'edit-btn'} size={"small"} shape={'circle'} onClick={() => {
                        setEditMode(true);
                        loadItem(record.id);
                    }}><EditOutlined/></Button>}
                    {props.customAction ? props.customAction(record) : null}
                    {props.canEdit && hasDeleteAction(record) && <Popconfirm
                        title="Вы уверены что хотите удалить запись?"
                        okText="Да"
                        onConfirm={() => {
                            removeItem(record.id);
                        }}
                        cancelText="Нет"
                    >
                        <Button size="small" shape="circle" className={'del-btn'}><DeleteOutlined/></Button>
                    </Popconfirm>}
                </div>;
            }
        }];
        return config;
    }

    const onChangeValue = () => {
        if (props.onChangeValue) {
            props.onChangeValue(item, formInstance);
        }
        setUpdate(Math.random());
    }

    const renderField = (config, index) => {

        if (props.onRenderField) {
            if (!props.onRenderField(config, item)) {
                return;
            }
        }

        const rules = typeof config.rules === 'function' ? config.rules(item) : config.rules || [];

        if (typeof config.render === 'function') {
            return <Form.Item key={index} label={config.title} name={config.name} rules={rules}>
                {config.render(item, formInstance, setItem)}
            </Form.Item>;
        } else if (typeof config === 'object') {
            if (config.type === 'text') {
                return <Form.Item key={index} label={config.title} name={config.name} rules={rules}>
                    <Input placeholder={config.title} autoFocus={config.autoFocus || false} style={config.style}/>
                </Form.Item>
            }
            if (config.type === 'checkbox') {
                return <Form.Item key={index} name={config.name} valuePropName={'checked'} rules={rules}
                ><Checkbox>{config.title}</Checkbox>
                </Form.Item>
            }
            if (config.type === 'date') {
                return <Form.Item label={config.title} key={index} name={config.name} rules={rules}>
                    <DatePicker format={'DD.MM.YYYY'} placeholder={config.title}/>
                </Form.Item>
            }
            if (config.type === 'hidden') {
                return <Form.Item key={index} name={config.name} hidden={true}>
                    <Input/>
                </Form.Item>
            }
            if (config.type === 'separator') {
                return <h3 key={config.title}>{config.title}</h3>
            }
            if (config.type === 'list') {
                return <div key={config.name}><h3>{config.title}</h3>
                    <Form.Item key={index} name={config.name} hidden={true}>
                        <Input/>
                    </Form.Item>
                    <List selector={config.selector}
                          columns={
                              config.columns || [{title: 'Название', dataIndex: 'name', key: 'name'}]
                          }
                          value={item[config.name]}
                          onChange={(value) => {
                              formInstance.setFieldsValue({[config.name]: value});
                          }}
                          canEdit={props.canEdit}
                    />
                </div>
            }

        } else if (typeof config === 'string') {
            return <Form.Item key={index} label={config} name={config} rules={rules}>
                <Input autoFocus={config.autoFocus || false}/>
            </Form.Item>
        }

    }

    const loadList = () => {
        if (props.url) {
            setLoading(true);
            let url = props.url;
            if (searchString) {
                url += '?search=' + searchString;
            }
            api.requestJSON('get', url).then(response => {
                setData(props.onMapLoad ? props.onMapLoad(response) : response);
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setData(props.values);
        }
    }

    const saveRecord = (record, showErrors = true) => {
        return new Promise((resolve, reject) => {
            if (props.url) {
                const method = record.id ? 'put' : 'post';
                const url = record.id ? `${props.url}/${record.id}` : props.url;
                record = props.onMapSave ? props.onMapSave(record) : record;
                api.requestJSON(method, url, record).then(response => {
                    loadList();
                    NotifySuccess('Запись сохранена');
                    resolve(response);
                }).catch(error => {
                    if (showErrors) {
                        NotifyError('Ошибка при сохранении');
                    }
                    reject(error);
                });
            } else {
                if (!record.id) {
                    record.id = -Math.floor(Math.random() * 1000000);
                }
                const index = data.findIndex(item => item.id === record.id);
                if (index === -1) {
                    data.push(record);
                } else {
                    data[index] = record;
                }
                setData([...data]);
                if (props.onChange) {
                    props.onChange(data);
                }
            }
        });
    }

    const removeItem = (id) => {
        if (props.url) {
            api.requestJSON('delete', props.url + '/' + id).then(response => {
                loadList();
                if (props.onChange) {
                    props.onChange(response);
                }
            });
        } else {
            const index = data.findIndex(item => item.id === id);
            if (index !== -1) {
                data.splice(index, 1);
                setData([...data]);
            }
            if (props.onChange) {
                props.onChange(data);
            }
        }
    }

    const processDates = (data) => {
        if (Array.isArray(props.editConfig)) {
            for (let field in props.editConfig) {
                if (props.editConfig[field].type === 'date') {
                    const fieldName = props.editConfig[field].name;
                    let value = data[fieldName];
                    if (value) {
                        data[fieldName] = dayjs(value);
                    } else {
                        data[fieldName] = null;
                    }
                }
            }
        }
        return data;
    }

    const loadItem = (id) => {
        if (props.url) {
            setLoading(true);
            api.requestJSON('get', props.url + '/' + id).then(response => {
                response = props.onMapItem ? props.onMapItem(response) : response;
                response = processDates(response);
                setItem(response);
            }).finally(() => {
                setLoading(false);
            });
        } else {
            let value = data.find(item => item.id === id);
            setItem(value);
        }
    }

    const create = () => {
        setEditMode(true);
        setItem(props.initialItemValues || {});
    }

    useEffect(() => {
        loadList();
        onChangeValue();
    }, []);

    useEffect(() => {
        if (editMode) {
            formInstance.setFieldsValue(item);
        }
        onChangeValue();
    }, [item]);

    useEffect(() => {
        if (searchString !== undefined) {
            loadList();
        }
    }, [searchString]);

    const onOk = () => {
        formInstance.validateFields().then(values => {
            saveRecord(values, false).then(() => {
                setEditMode(false);
                setItem({});
                formInstance.resetFields();
            }).catch(err => {
                let errors = [];
                if (err.errors) {
                    for (let field in err.errors) {
                        errors.push(err.errors[field]);
                    }
                    NotifyError(errors.join('\n'));
                }
            });
        }).catch(err => {
            console.log(err);
            NotifyError('Заполните правильно все обязательные поля');
        });
    }
    const onCancel = () => {
        setItem({});
        setEditMode(false);
        formInstance.resetFields();
    }

    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        if (props.onSelectChange) {
            props.onSelectChange(selectedRowKeys, selectedRows);
        }
    }

    const hasCreateAction = () => {
        const result = !props.actions || (Array.isArray(props.actions) && props.actions.includes('create'));
        if (props.createRecordEnabled) {
            return result && props.createRecordEnabled();
        }
        return result;
    }

    const hasEditAction = (record) => {
        const result = !props.actions || (Array.isArray(props.actions) && props.actions.includes('edit'));
        if (record && props.editRecordEnabled) {
            return result && props.editRecordEnabled(record);
        }
        return result;
    }

    const hasDeleteAction = (record) => {
        const result = !props.actions || (Array.isArray(props.actions) && props.actions.includes('delete'));
        if (record && props.editRecordEnabled) {
            return result && props.deleteRecordEnabled(record);
        }
        return result;
    }

    const getData = () => {
        if (props.onDataFilter) {
            return props.onDataFilter(data);
        }
        return data;
    }

    let tableParams = {
        size: 'small',
        dataSource: getData(), columns: getViewConfig(), rowKey: 'id', style: {marginTop: 20}, loading: loading
    }

    if (props.selectionMode) {
        tableParams.rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange
        }
    }

    useEffect(() => {
        loadList();
    }, [props.values]);

    const editConfig = typeof props.editConfig === 'function' ? props.editConfig(formInstance, props.initialItemValues) : props.editConfig;

    return <Card title={props.title} onKeyDown={
        (e) => {
            if (e.key === 'Enter' && editMode) {
                onOk();
            }
        }
    }>
        {hasCreateAction() &&
            <Row>
                <Col span={12}>
                    {props.url &&
                        <Form>
                            <Form.Item label={'Поиск'}>
                                <Input.Search onChange={(e) => {
                                    setSearchString(e.target.value);
                                }}></Input.Search>
                            </Form.Item>
                        </Form>}
                </Col>
                <Col span={12} style={{display: 'flex', justifyContent: 'end'}}>
                    {props.canEdit && <Button type={'primary'} onClick={create}>Добавить</Button>}
                </Col>
            </Row>

        }
        <Table {...tableParams} rowKey={'id'}/>
        {update && editMode && <Modal title={item.id ? props.editTitle : props.createTitle}
                                      open={editMode}
                                      destroyOnClose={true}
                                      maskClosable={false}
                                      footer={<>
                                          {props.canEdit && <Button type={'primary'} onClick={onOk}>Сохранить</Button>}
                                          <Button onClick={onCancel}>Отмена</Button>
                                      </>}
                                      onOk={onOk}
                                      onCancel={onCancel}
                                      width={props.editorWidth || '80%'}
        >
            {loading ? <Spin style={{display: 'flex', justifyContent: 'center'}}/> :
                props.editConfig ?
                    <ConfigProvider locale={locale}>
                        <Row>
                            <Col span={24} style={{padding: 20}}>
                                <Form style={{padding: '10px'}} form={formInstance}
                                      initialValues={props.initialItemValues}
                                      onValuesChange={(changedValues, allValues) => {
                                          setItem(allValues);
                                      }}>
                                    <Form.Item name={'id'} hidden={true}>
                                        <Input type={'hidden'} value={null}/>
                                    </Form.Item>
                                    {editConfig && <>
                                        {editConfig.map((config, index) => {
                                            return renderField(config, index);
                                        })
                                        }
                                    </>}
                                </Form>
                            </Col>
                        </Row>
                    </ConfigProvider>
                    : null}
        </Modal>}
    </Card>;
}

Crud.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    values: PropTypes.array,
    viewConfig: PropTypes.array.isRequired,
    editConfig: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    initialItemValues: PropTypes.object,
    actions: PropTypes.array,
    customAction: PropTypes.func,
    selectionMode: PropTypes.oneOf(['single', 'multiple']),
    selectedRowKeys: PropTypes.array,
    onChange: PropTypes.func,
    onMapLoad: PropTypes.func,
    onMapSave: PropTypes.func,
    onMapItem: PropTypes.func,
    onDataFilter: PropTypes.func,
    onItemLoaded: PropTypes.func,
    onSelectChange: PropTypes.func,
    onChangeValue: PropTypes.func,
    onRenderField: PropTypes.func,
    editorWidth: PropTypes.string,
    editTitle: PropTypes.string,
    createTitle: PropTypes.string,
    canEdit: PropTypes.bool,
    editRecordEnabled: PropTypes.func,
    createRecordEnabled: PropTypes.func,
    deleteRecordEnabled: PropTypes.func,
};

Crud.defaultProps = {
    actions: ['create', 'edit', 'delete'],
    canEdit: true,
}

export default Crud;
